.results-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    color: #eee;
}

.result-value {
    font-size: 2rem;
    font-weight: bold;
}

.result-label {
    font-size: 1.2rem;
    opacity: 0.7;
}

.submit-button {
    background-color: #eee;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.results-submission {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
}

.results-submission-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    gap: 1rem;
}

.submit-button-small {
    background-color: #eee;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-input {
    background-color: #eee;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

