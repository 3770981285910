.game {
    /* position: relative; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.utils-container {
    position: absolute;
    top: 10%;
    /* left: 50%; */
}

.main-container {
    margin-top: 20em;
}

button {
    background-color: transparent;
    border: none;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    cursor: pointer;
}

button:hover {
    transform: scale(1.1);
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-text {
    color: #fff;
    font-size:1rem;
    font-weight: semi-bold;
    opacity: 0.4;
}

.footer-link {
    color: #fff;
    text-decoration: none;
}
