.color-settings-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
    margin-top: -10px;
}

.color-options {
    display: flex;
    gap: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.color-option {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #eee;
}
  