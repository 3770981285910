.timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer {
    font-size: 2rem;
    font-weight: bold;
}

.timer-label {
    font-size: 1.2rem;
}