.typing-area-block-container {
    position: relative;
    overflow: hidden;
    height: 3rem * 3;
}

.sentence-block-container {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    line-height: 3rem;
    max-height: 100%;
    max-width: 80vw;
    overflow: auto;
}

.typing-area-block {
    padding: 10px;
    font-size: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    color: #f3f3f3;
    text-align: left;
}

.cursor {
    background-color: #555;
    /* width: 0.3rem; */
}

.not-typed {
    opacity: 0.6;
}

.space-wrong {
    display: inline-block;
    width: 0rem;
    height: 1.2rem;
    margin: 0 0.2rem;
    margin-bottom: 0.2rem;
    vertical-align: middle;
}

.space-placeholder {
    display: inline-block;
    min-width: 0.25em;
    word-spacing: 0;
}

@media (max-width: 768px) {
    .typing-area-block {
        font-size: 1.5rem;
    }

    .sentence-block-container {
        max-width: 100%;
        align-items: center;
    }
}