.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 18rem;
    padding: 0 1rem;
    box-sizing: border-box;
    text-align: center;
    color: #ccc;
    font-family: Arial, Helvetica, sans-serif;
}
  
.leaderboard_title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: .5rem;
    color: #ddd;
}
  
.leaderboard_header {
    font-size: 1.3rem;
    font-weight: 600;
    opacity: 0.6;
    color: #ddd;
    padding: 0.5rem;
}
  
table {
    border-collapse: separate;
    border-spacing: 1rem;
    width: 60%;
}

th {
    /* text-align: left; */
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.9;
    color: #fff;
    width: 3em;
}
  
.leaderboard_row {
    height: 2rem;
    border-radius: 0.5rem;
    color: #ddd;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
    opacity: 0.8;
}
  
.leaderboard_row:hover {
    opacity: 1;
}
  
.leaderboard_rank {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    text-align: center;
}
  
.down-arrow {
    width: 1em;
    height: 1em;
    justify-self: center;
    align-self: center;
}